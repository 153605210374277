import * as d3 from 'd3';
import * as topojson from 'topojson-client';

let canvas = document.getElementById('company-map-canvas');
if (canvas !== null) (async () => {
    // We also have the option to load land-110m.json, but then we cannot filter out Antarctica
    const response = await fetch('https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json');
    const world = await response.json();
    const countries = world.objects.countries;
    // Filter out Antarctica
    countries.geometries = countries.geometries.filter(d => d.id != 10);
    const land = topojson.feature(world, world.objects.countries);

    // List of cities to pin on the map
    const places = [
        {
            name: "Palo Alto, CA",
            location: { lat: 37.468319, lon: -122.143936 }
        },
        {
            name: "Stuttgart, Germany",
            location: { lat: 48.783333, lon: 9.183333 }
        }
    ];

    // Default sizing for projections
    // TODO: confirm?
    const width = 960;
    const height = 500;

    const projection = d3.geoNaturalEarth1()
        .fitSize([width, height], {type: "Sphere"})
        .center([0, 5])
        // Rotate slightly to northern and western hemisphere
        .rotate([5, -5, 0]);
    const path = d3.geoPath(projection);

    const svg = d3.create("svg")
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', [0, 0, width, height])
        .attr('class', 'mx-auto');

    // Map areas
    svg.append('g')
        .selectAll('path')
        .data(land.features)
        .join('path')
        .attr('d', path)
        .attr('fill', 'rgb(232, 239, 248)');
        // .attr('fill', 'rgb(200, 200, 200)');

    // City pins
    const pins = svg.append('g').attr('class', 'pins');
    pins.selectAll('.pin')
        .data(places)
        .join('circle')
        .attr('class', 'pin')
        .datum(d => projection([d.location.lon, d.location.lat]))
        .attr('r', 3)
        .attr('cx', d => d[0])
        .attr('cy', d => d[1]);

    canvas.appendChild(svg.node());

    // Labels
    pins.selectAll('.label')
        .data(places)
        .join('text').attr('class', 'label')
        .text(d => d.name)
        .datum(d => projection([d.location.lon, d.location.lat]))
        .attr('x', d => d[0])
        .attr('y', d => d[1])
        .attr('dy', -30)
        .attr('text-anchor', 'middle')
        .style('font-family', 'sans-serif')
        .style('font-size', '0.6em')
        .style('font-weight', 'bolder');

    // Label backgrounds
    pins.selectAll('.label').each(function addBackground(this: SVGTextElement) {
        const bbox = this.getBBox();
        const margin = 5
        const rect = d3.create('svg:rect')
            .attr('class', 'label-bg')
            .attr('x', bbox.x - margin)
            .attr('y', bbox.y - margin)
            .attr('rx', 5)
            .attr('width', bbox.width + 2 * margin)
            .attr('height', bbox.height + 2 * margin);
        this.parentNode.insertBefore(rect.node(), this);
    });
})();