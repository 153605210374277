import * as d3 from 'd3';

type CartesianPoint = { x: number, y: number };
type Rect = CartesianPoint & { width: number, height: number };

let canvas = document.getElementById('solution-radar-chart-canvas');
if (canvas !== null) {
    // Dimensions in SVG coordinate grid units
    const dimension = 700;
    const width = dimension;
    const height = dimension;
    const radius = 0.93 / 2 * dimension;

    // Define radial scale (from values to internal coordinate grid unites)
    const radial_max = 10;
    const radial = d3.scaleLinear()
        .domain([0, radial_max])
        .range([0, radius]);

    // Define conversion from polar to cartesian coordinates
    function cartesian(r: number, theta: number): CartesianPoint {
        const x = r * Math.cos(theta);
        const y = r * Math.sin(theta);
        return {x, y};
    }

    // Feature set
    // These attributes will be plotted in this order, clockwise, starting from (0, radius)
    type Feature = {
        title: string,
        key: string,
        theta: number,
        flip: boolean,
        winner: Series,
    } & CartesianPoint;
    const features: Partial<Feature>[] = [
        {title: "FOV"},
        {title: "Angular resolution"},
        {title: "Classification"},
        {title: "Color detection"},
        {title: "Interference robustness"},
        {title: "Low-cost", key: 'cost'},
        {title: "Lighting robustness"},
        {title: "Velocity resolution"},
        {title: "Range resolution"},
        {title: "Weather robustness"},
        {title: "Range"},
    ];

    // Possible states for the chart, each enables or disables some elements
    type State = {
        key: string,
        duration?: number,
        enter?: () => void,
        exit?: () => void,
    }
    const states: State[] = [
        {
            key: 'camera-strengths',
            duration: 1500
        },
        {
            key: 'lidar-strengths',
            duration: 1500
        },
        {
            key: 'radar-strengths',
            duration: 1500
        },
        {
            key: 'lir-boost-imaging',
            duration: 3000,
            enter() {
                const lir_centroid = polygon_centroid(series_cartesian(series_radar.data_lir));

                animate_text(
                    paths_text_radar,
                    paths_text_background_radar,
                    paths_g_radar,
                    svg.node(),
                    2000,
                    'LIR',
                    lir_centroid,
                    paths_text_background_padding);

                paths_radar.transition().duration(2000)
                    .attr('d', d => series_d(d.data_lir));
            }
        },
        {
            key: 'lir-with-camera',
            enter() {
                animate_text(
                    paths_text_radar,
                    paths_text_background_radar,
                    paths_g_radar,
                    svg.node(),
                    2000,
                    'Multi-modal LIR and vision',
                    {x: 0, y: 0},
                    paths_text_background_padding);

                // TODO: don't run this animation if it's already running
                paths_radar.transition().duration(2000)
                    .attr('d', d => series_d(d.data_lir));
            }
        },
    ];

    // Series data and metadata
    type SeriesData = Record<string, number>;
    type Series = {
        key: string,
        title?: string,
        color: string,
        data: SeriesData,
        data_lir?: SeriesData,
    }
    const series: Series[] = [
        {
            key: 'lidar',
            title: 'LIDAR',
            color: '#FFBF00',
            data: {
                color_detection: 1,
                interference_robustness: 8,
                cost: 1,
                lighting_robustness: 8,
                velocity_resolution: 0,
                range_resolution: 5,
                weather_robustness: 3,
                range: 5,
                fov: 10,
                angular_resolution: 8,
                classification: 6,
            },
        },
        {
            key: 'camera',
            title: 'Camera',
            color: '#009D3A',
            data: {
                color_detection: 10,
                interference_robustness: 10,
                cost: 8,
                lighting_robustness: 3,
                velocity_resolution: 1,
                range_resolution: 0,
                weather_robustness: 3,
                range: 4,
                fov: 7,
                angular_resolution: 10,
                classification: 10,
            },
        },
        {
            key: 'radar',
            title: 'Radar',
            color: '#0039A2',
            data: {
                color_detection: 0,
                interference_robustness: 3,
                cost: 10,
                lighting_robustness: 10,
                velocity_resolution: 10,
                range_resolution: 9,
                weather_robustness: 10,
                range: 9,
                fov: 4,
                angular_resolution: 2,
                classification: 2,
            },
            data_lir: {
                color_detection: 0,
                interference_robustness: 6,
                cost: 8,
                lighting_robustness: 10,
                velocity_resolution: 10,
                range_resolution: 10,
                weather_robustness: 10,
                range: 9,
                fov: 7,
                angular_resolution: 8,
                classification: 6,
            },
        },
    ];
    const is_radar_series = (d: Series) => d.key === 'radar';
    const series_radar = series.filter(is_radar_series)[0];

    // Calculate additional metadata for each feature
    const features_length = features.length;
    const features_theta_span = 2 * Math.PI / features_length;
    for (let [i, feature] of features.entries()) {
        let theta = feature.theta = i * features_theta_span - Math.PI / 2;
        Object.assign(features, cartesian(radial(radial_max), theta));

        // Generate a key for the feature, or use whatever was defined above
        feature.key ||= to_id(feature, '_');

        // Flag whether or not to flip the feature label
        feature.flip = theta > 0 && theta < Math.PI;

        // Get the series color
        feature.winner = series.map(
            (s: Series, i): [number, Series] => [s.data[feature.key], s]
        ).reduce(
            (r, a) => (a[0] > r[0] ? a : r)
        )[1];
    }

    // Function to generate a line from series data
    type LineGenerator = (points: CartesianPoint[]) => string;
    // @ts-ignore
    const line: LineGenerator = d3.line().x(d => d.x).y(d => d.y);

    function series_cartesian(d: SeriesData) {
        return features.map((f: Feature) => cartesian(radial(d[f.key]), f.theta));
    }

    function series_d(d: SeriesData) {
        const points = series_cartesian(d);
        // Close polygon
        points.push(points[0]);
        return line(points);
    }

    // Create root <svg> element
    const svg = d3.create('svg')
        // Scale aspect ratio relative to center
        .attr('preserveAspectRatio', 'xMidYMid meet')
        // View box with origin at the center
        .attr('viewBox', [-width / 2, -height / 2, width, height])
        .attr('class', 'mx-auto');

    // Grid circles
    const ticks = radial.ticks(5).reverse()
    svg.append('g')
        .selectAll('circle')
        .data(ticks)
        .join('circle')
        .attr('r', radial)
        .attr('fill', (d, i) => d3.interpolateGreys((i+1) / ticks.length / 2.0));

    // Axis lines
    svg.append('g')
        .selectAll('line')
        .data(features)
        .join('line')
        .attr('x2', d => d.x)
        .attr('y2', d => d.y);

    // Series areas
    const paths = svg.append('g').selectAll('path')
        .data(series)
        .join('path')
        .attr('class', d => `series series-${to_id(d)}`)
        .attr('d', d => series_d(d.data))
        .attr('fill', d => d.color)
        .attr('stroke', 'none');
    const paths_radar = paths.filter(is_radar_series);

    // Curved paths for axis labels
    svg.append('defs').selectAll('path')
        .data(features)
        .join('path')
        .attr('id', (d, i) => `feature-${i}-path`)
        .attr('d', d => {
            let radius_arc = radius + 16;
            let start = cartesian(radius_arc, d.theta - features_theta_span / 2 * 1.5);
            let end = cartesian(radius_arc, d.theta + features_theta_span / 2 * 1.5);
            let sweep = 1;

            // Flip labels in the bottom half of the circle
            if (d.flip) {
                [start, end] = [end, start];
                sweep = 0;
            }

            return `M ${start.x} ${start.y} A ${radius_arc} ${radius_arc} 0 0 ${sweep} ${end.x} ${end.y}`;
        });

    // Axis labels
    svg.append('g').selectAll('text')
        .data(features)
        .join('text')
        .attr('class', d => `label label-${to_id(d)} label-winner-${to_id(d.winner)}`)
        .style('fill', d => d.winner.color)
        // <textPath> element to follow curved path created above
        .append('textPath')
        .attr('xlink:href', (d, i) => `#feature-${i}-path`)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('startOffset', '50%')
        .text(d => d.title);

    // Append to DOM
    canvas.appendChild(svg.node());

    // Series labels
    const paths_g = paths.datum(function (this: SVGPathElement, d: Series): Series & CartesianPoint {
        return {...polygon_centroid(series_cartesian(d.data)), ...d};
    })
        .select(function (this: SVGPathElement) {
            const parent = <SVGGElement>this.parentNode;
            return parent.appendChild(make_svg_element('g'));
        })
        .attr('class', d => `series-label series-label-${to_id(d)}`)
        .attr('transform', d => `translate(${d.x}, ${d.y})`);
    const paths_g_radar = paths_g.filter(is_radar_series);
    const paths_text = paths_g.filter(d => d.title !== void 0)
        .append('text')
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text(d => d.title)
        .attr('fill', 'white')
        .style('font-family', 'sans-serif');
    const paths_text_radar = paths_text.filter(is_radar_series);
    const paths_text_background_padding = {x: 10, y: 8};
    const paths_text_background = paths_text
        .datum(function (this: SVGTextElement, d): Series & Rect {
            const {x, y, width, height} = this.getBBox();
            return {...d, x, y, width, height};
        })
        .select(function (this: SVGTextElement) {
            const parent: SVGGElement = <SVGGElement>this.parentNode;
            return parent.insertBefore(make_svg_element('rect'), this);
        })
        .attr('x', d => -d.width / 2 - paths_text_background_padding.x)
        .attr('y', d => -d.height / 2 - paths_text_background_padding.y)
        .attr('width', d => d.width + paths_text_background_padding.x * 2)
        .attr('height', d => d.height + paths_text_background_padding.y * 2)
        .attr('rx', Math.max(paths_text_background_padding.x, paths_text_background_padding.y))
        .attr('fill', d => d.color)
        .attr('stroke', 'none');
    const paths_text_background_radar = paths_text_background.filter(is_radar_series);

    // Launch state cycle
    next_state(0);

    function next_state(index: number) {
        if (index >= states.length)
            return;

        // Enter state
        let state = states[index];
        toggle_state(index, true);

        if (state.duration !== void 0)
            setTimeout(() => {
                toggle_state(index, false);
                next_state(index + 1);
            }, state.duration);
    }

    function toggle_state(index: number, on: boolean = true) {
        let state = states[index];
        svg.classed(`state state-${state.key}`, on);

        if (on)
            state.enter && state.enter();
        else
            state.exit && state.exit();
    }
}

function to_id(d, sep = '-', prop = 'title') {
    return d.key || d[prop].toLowerCase().replaceAll(' ', sep);
}

function animate_text(text_selection: d3.Selection<SVGTextElement, any, any, any>,
                      background_selection: d3.Selection<SVGRectElement, any, any, any>,
                      group_selection: d3.Selection<SVGGElement, any, any, any>,
                      parent: SVGElement,
                      duration: number, text: string, position: CartesianPoint, padding: CartesianPoint) {
    const {width, height} = get_text_dimensions(parent, text);

    text_selection
        .transition().duration(duration / 2).style('opacity', 0)
        .transition().duration(0).text(text)
        .transition().duration(duration / 2).style('opacity', 1);

    background_selection.transition().duration(duration)
        .attr('x', -width / 2 - padding.x)
        .attr('y', -height / 2 - padding.y)
        .attr('width', width + padding.x * 2)
        .attr('height', height + padding.y * 2)

    group_selection.transition().duration(duration)
        .attr('transform', `translate(${position.x}, ${position.y})`)
}

function get_text_dimensions(svg: SVGElement, text: string, fontFamily: string = 'sans-serif') {
    const elem = make_svg_element('text');
    elem.style.visibility = 'hidden';
    elem.style.fontFamily = fontFamily;
    elem.innerHTML = text;

    svg.appendChild(elem);
    const bbox = elem.getBBox();
    svg.removeChild(elem);
    return {
        width: bbox.width,
        height: bbox.height
    };
}

function make_svg_element(name: 'text'): SVGTextElement;
function make_svg_element(name: 'rect'): SVGRectElement;
function make_svg_element(name: 'g'): SVGGElement;
function make_svg_element(name: string): SVGElement {
    return document.createElementNS('http://www.w3.org/2000/svg', name);
}

function polygon_centroid(points: CartesianPoint[]): CartesianPoint {
    const length = points.length;

    const p0 = points[0];
    let cx = 0, cy = 0, ta = 0;
    for (let i = 1; i < length; ++i) {
        const p1 = points[i];
        const p2 = points[(i + 1) % length];

        const area = (p1.x - p0.x) * (p2.y - p0.y) - (p2.x - p0.x) * (p1.y - p0.y);
        cx += area * (p0.x + p1.x + p2.x) / 3;
        cy += area * (p0.y + p1.y + p2.y) / 3;
        ta += area;
    }

    const x = cx / ta;
    const y = cy / ta;
    return {x, y};
}