// Attach scroll listener if scroll header is present
let header = document.querySelector('.home header');
if (header !== null) {
    let handler = onscroll(header.offsetHeight, header.classList); handler();
    window.addEventListener('scroll', handler);
}

// Generate scroll handler for some offset and class list
function onscroll(headerHeight, headerClasses) {
    let threshold = window.innerHeight - headerHeight;
    let prevScrollY = null, currScrollY;

    return () => {
        let init = prevScrollY === null;
        currScrollY = window.scrollY;

        // Update classes when scrolling above or below threshold
        if ((init || prevScrollY < threshold) && currScrollY >= threshold) {
            headerClasses.remove('header-dark');
            headerClasses.add('header-light');
        } else if ((init || prevScrollY >= threshold) && currScrollY < threshold) {
            headerClasses.remove('header-light');
            headerClasses.add('header-dark');
        }

        prevScrollY = currScrollY;
    };
}