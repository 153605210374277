let form = document.getElementById('contact');
if (form !== null) {
    form.addEventListener('submit', submit);
}

function submit(e: SubmitEvent) {
    e.preventDefault();

    const form = <HTMLFormElement>e.target;
    const data = new FormData(form);
    const params = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(data as any).toString()
    }

    fetch('/', params).then(() => {
        const button: HTMLButtonElement = form.querySelector('button[type=submit]');
        button.disabled = true;
        button.classList.add('hidden');

        const success = form.querySelector('#success-message');
        success.classList.remove('hidden');
    });
}